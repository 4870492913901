export const hasScrollBar = () =>
    document.body.scrollHeight > document.documentElement.clientHeight;

export const getScrollbarSize = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.cssText =
        'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
    document.body.appendChild(scrollDiv);
    const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarSize;
};
