import { dcFactory } from '@deleteagency/dc';
import { nanoid } from 'nanoid';
import { dynamicStylesService } from 'general/js/dynamic-styles-service';
import { deviceObserver } from 'general/js/device-observer';
import { getScrollbarSize } from 'general/js/scrollbar-handler';
import { scrollService } from 'general/js/scroll-service';

declare global {
    interface Window {
        appConfig: string;
    }
}

class App {
    public config = window.appConfig || '';

    private id = nanoid(10);

    public init(): void {
        dcFactory.init();
        this._setScrollbarSize();
        this._watchViewport();
        this._watchScroll();
    }

    private _watchViewport(): void {
        this._setViewportSize();
        if (!window.location.href.match('EPiServer/CMS')) {
            deviceObserver.subscribeOnResize(this._setViewportSize);
        } else {
            document.body.classList.add('in-cms');
        }
    }

    private _setViewportSize = (): void => {
        dynamicStylesService.setStyles({
            id: this.id + 'v',
            selector: '.body',
            css: `--viewport-height: ${window.innerHeight}px;`,
        });
    };

    private _setScrollbarSize = (): void => {
        dynamicStylesService.setStyles({
            id: this.id + 's',
            selector: '.body',
            css: `--scrollbar: ${getScrollbarSize()}px;`,
        });
    };

    private _watchScroll = (): void => {
        let position = window.pageYOffset;
        scrollService.subscribe(this.id + 'scroll', () => {
            if (position <= window.pageYOffset) {
                document.documentElement.classList.add('is-scrolled-down');
            } else {
                document.documentElement.classList.remove('is-scrolled-down');
            }
            if (window.innerHeight + window.pageYOffset + 50 >= document.body.clientHeight) {
                document.documentElement.classList.add('is-end-of-page');
            } else {
                document.documentElement.classList.remove('is-end-of-page');
            }

            position = window.pageYOffset;
        });
    };
}

export const app = new App();
